import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { env } from "../../environment/environment";
@Injectable({
  providedIn: 'root'
})
export class KeywordsService {
  [x: string]: any;
  private keywordsUrl = env.API_URL +'/keywords';

  constructor(private http: HttpClient) {}

  getKeywords(id?: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    });
    return this.http.get<any>(this.keywordsUrl,{headers});
  }
}
