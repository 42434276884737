import {
  CanActivateFn,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { map, take, of } from "rxjs";

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const storedUserType = localStorage.getItem('userType');

  if (storedUserType) {
    if (storedUserType === 'marveler' || storedUserType === 'counselor') {
      return of(true);
    } else {
      return of(false);
    }
  }

  return authService.getUserType().pipe(
    take(1),
    map((userType) => {
      if (userType === 'marveler' || userType === 'counselor') {
        localStorage.setItem('userType', userType);
        return true;
      } else {
        return false;
      }
    })
  );
};