<div #header>
    <app-header class="header"></app-header>
    <app-header-mobile class="header-mobile"></app-header-mobile>
</div>
<div class="wrapper">
    <div class="sidebar"
         #sidebar
         [ngClass]="{'open': isOpenSidebar}">
        <app-sidebar></app-sidebar>
    </div>
    <div class="inner">
        <router-outlet />
    </div>
</div>