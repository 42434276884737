import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormControl } from "@angular/forms";
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { AuthService } from "../../shared/services/auth.service";
import { SidebarService } from "../sidebar/services/sidebar.service";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CommonModule } from "@angular/common";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-header-mobile",
  standalone: true,
  imports: [
    MatIcon,
    CommonModule,
    RouterModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: "./header-mobile.component.html",
  styleUrls: ["./header-mobile.component.scss"],
  providers: [provideNativeDateAdapter()],
})
export class HeaderMobileComponent implements OnInit, AfterViewInit {
  peopleSummary: string | null = null;
  adultValue: number = 0;
  childrenValue: number = 0;
  infantValue: number = 0;
  petValue: number = 0;
  dropdownOpen: boolean = false;
  headerBackgroundColor: string = "var(--primary-color)";
  showFilter: boolean = true;
  predictions: google.maps.places.AutocompletePrediction[] = [];
  date = new FormControl<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });
  formattedDateRange: string = "";
  @ViewChild("search", { static: false }) searchElementRef!: ElementRef;
  europeanCountryCodes = [
    "AL",
    "AD",
    "AM",
    "AT",
    "AZ",
    "BY",
    "BE",
    "BA",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "GE",
    "DE",
    "GR",
    "HU",
    "IS",
    "IE",
    "IT",
    "KZ",
    "XK",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "MD",
    "MC",
    "ME",
    "NL",
    "MK",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "RS",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TR",
    "UA",
    "GB",
    "VA",
  ];

  private readonly MAX_VALUE = 10;

  constructor(
    private router: Router,
    private authService: AuthService,
    public sidebarService: SidebarService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.authService.getUserType().subscribe((userType) => {
      if (userType) {
        this.updateHeader(userType);
      } else {
        userType = localStorage.getItem('userType');
        this.updateHeader(userType);
      }
    });
  }

  async ngAfterViewInit() {
    await google.maps.importLibrary("places");
    const searchBox = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );

    searchBox.addListener("place_changed", () => {
      const place = searchBox.getPlace();
      const addressComponents = place.address_components || [];

      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );
      const countryCode = countryComponent ? countryComponent.short_name : "";
      if (this.europeanCountryCodes.includes(countryCode)) {
        console.log("Selected city is in Europe:", place.name);
        this.searchElementRef.nativeElement.value = place.name;
      } else {
        this.searchElementRef.nativeElement.value = "";
        console.log("Selected city is not in Europe:", place.name);
      }
    });
  }

  private updateHeader(userType: string | null): void {
    if (userType === "counselor") {
      this.headerBackgroundColor = "var(--secondary-color)";
      this.showFilter = false;
    } else if (userType === "marveler") {
      this.headerBackgroundColor = "var(--primary-color)";
      this.showFilter = true;
    } else {
      this.headerBackgroundColor =
        "linear-gradient(90deg, rgba(18, 103, 106, 1) 0%, rgb(155, 227, 254) 50%, rgba(157, 7, 89, 1) 100%)";
      this.showFilter = window.innerWidth > 992;
    }
  }

  onSubmit() {
    this.router.navigate(["/explorer"]);
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getPeopleSummary(): string {
    const summary = [];
    if (this.adultValue > 0)
      summary.push(`${this.adultValue} Adult${this.adultValue > 1 ? "s" : ""}`);
    if (this.childrenValue > 0)
      summary.push(
        `${this.childrenValue} Child${this.childrenValue > 1 ? "ren" : ""}`
      );
    if (this.infantValue > 0)
      summary.push(
        `${this.infantValue} Infant${this.infantValue > 1 ? "s" : ""}`
      );
    if (this.petValue > 0)
      summary.push(`${this.petValue} Pet${this.petValue > 1 ? "s" : ""}`);
    return summary.length > 0 ? summary.join(", ") : "";
  }

  increase(type: string) {
    switch (type) {
      case "adult":
        if (this.adultValue < this.MAX_VALUE) this.adultValue++;
        break;
      case "children":
        if (this.childrenValue < this.MAX_VALUE) this.childrenValue++;
        break;
      case "infant":
        if (this.infantValue < this.MAX_VALUE) this.infantValue++;
        break;
      case "pet":
        if (this.petValue < this.MAX_VALUE) this.petValue++;
        break;
    }
    this.peopleSummary = this.getPeopleSummary();
  }

  decrease(type: string) {
    switch (type) {
      case "adult":
        this.adultValue = Math.max(0, this.adultValue - 1);
        break;
      case "children":
        this.childrenValue = Math.max(0, this.childrenValue - 1);
        break;
      case "infant":
        this.infantValue = Math.max(0, this.infantValue - 1);
        break;
      case "pet":
        this.petValue = Math.max(0, this.petValue - 1);
        break;
    }
    this.peopleSummary = this.getPeopleSummary();
  }

  onDateChange(event: MatDatepickerInputEvent<Date[]>): void {
    const [start, end] = event.value || [];
    if (start && end) {
      this.formattedDateRange = `${this.formatDate(start)} - ${this.formatDate(
        end
      )}`;
    } else {
      this.formattedDateRange = "";
    }
  }

  private formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  navigateToDashboard(): void {
    let userType = localStorage.getItem("userType");
    if (userType === "marveler") {
      this.router.navigate(["/dashboard-marveler"]);
    } else if (userType === "counselor") {
      this.router.navigate(["/dashboard-counselor"]);
    } else {
      this.router.navigate(["/home"]);
    }
  }
}
