import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  OnInit,
  NgModule,
} from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { HeaderComponent } from "../header/header.component";
import { HeaderMobileComponent } from "../header-mobile/header-mobile.component";
import { SidebarService } from "../sidebar/services/sidebar.service";
import { CommonModule } from "@angular/common";
import { UserService } from "../../services/user.service";
import { KeywordsService } from "../../services/keywords.service";

@Component({
  selector: "app-admin",
  standalone: true,
  imports: [
    RouterOutlet,
    SidebarComponent,
    HeaderComponent,
    CommonModule,
    HeaderMobileComponent,
  ],
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  @ViewChild("sidebar") sidebarRef!: ElementRef;
  @ViewChild("header") headerRef!: ElementRef;
  isOpenSidebar: boolean = false;
  keywordsList: string[] = [];
  userId: string = localStorage.getItem("currentUserId")!;

  constructor(
    public sidebarService: SidebarService,
    private userService: UserService,
    private keywordsService: KeywordsService
  ) {}

  ngOnInit() {
    this.sidebarService.getSidebarState.subscribe((state) => {
      this.isOpenSidebar = state;
    });

    this.findMe();

    this.keywordsService
      .getKeywords()
      .subscribe((response: any) => {
        this.updateKeywords(response.docs);
      });
  }
  findMe() {
    this.userService.findMe().subscribe();
  }

  updateKeywords(keywords: any[]): void {
    this.keywordsList = keywords.map((keyword) => keyword.name);
  }
}
