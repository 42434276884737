import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private loadingSubject = new BehaviorSubject<number>(0);
  loading$ = this.loadingSubject.asObservable();

  startLoading() {
    this.loadingSubject.next(this.loadingSubject.value + 1);
  }

  stopLoading() {
    this.loadingSubject.next(this.loadingSubject.value - 1);
  }

  incrementLoading() {
    this.loadingSubject.next(this.loadingSubject.value + 1);
  }

  decrementLoading() {
    this.loadingSubject.next(this.loadingSubject.value - 1);
  }
}