<header [ngStyle]="{'background-color': headerBackgroundColor}">  
  <button (click)="toggleSidebar()" class="menu-btn">
    <mat-icon [ngStyle]="{'color': headerBackgroundColor}">menu</mat-icon>
    <!-- <img src="assets/icons/menu.svg" alt="" class="menu-icon"> -->
  </button> 
  <div [ngStyle]="{'display': showFilter ? 'block' : 'none'}">
    <div class="filter">
      <div class="location-container">
        <input id="pac-input" class="filter-text" type="text" placeholder="" #search>
        <img class="location" src="assets/icons/location-pink.svg" alt="">
        <img class="arrow-down" src="assets/icons/arrow-down.svg" alt="">        
      </div>            
      <div class="calender">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <img src="assets/icons/arrow-down.svg" alt="">
        <mat-date-range-picker #picker></mat-date-range-picker>         
        <mat-date-range-input class="mdc-text-field" [rangePicker]="picker">
          <input matStartDate placeholder="">
          <input matEndDate placeholder="">
        </mat-date-range-input>
          
      </div>      
      <div class="dropdown">
        <div class="dropbtn">
          <div class="fitler-group-count">
            <input
              matInput
              readonly
              placeholder=""              
              (focus)="toggleDropdown()"
              (click)="toggleDropdown()"
              class="people-input custom-placeholder"
            />
            <img src="assets/icons/group.svg" alt="">
            <img src="assets/icons/arrow-down.svg" alt="">            
          </div>
        </div>
      
        <div class="dropdown-content" *ngIf="dropdownOpen">
          <div class="people">
            <div class="basic-info">
              <div class="name">Adults</div>
              <div class="age">Ages 14 or Above</div>
            </div>
            <div class="counter-container">
              <button mat-button (click)="decrease('adult')">-</button>
              <span class="counter-value">{{ adultValue }}</span>
              <button mat-button (click)="increase('adult')">+</button>
            </div>
          </div>
          <div class="people">
            <div class="basic-info">
              <div class="name">Children</div>
              <div class="age">Ages 2-13</div>
            </div>
            <div class="counter-container">
              <button mat-button (click)="decrease('children')">-</button>
              <span class="counter-value">{{ childrenValue }}</span>
              <button mat-button (click)="increase('children')">+</button>
            </div>
          </div>
          <div class="people">
            <div class="basic-info">
              <div class="name">Infants</div>
              <div class="age">Under 2</div>
            </div>
            <div class="counter-container">
              <button mat-button (click)="decrease('infant')">-</button>
              <span class="counter-value">{{ infantValue }}</span>
              <button mat-button (click)="increase('infant')">+</button>
            </div>
          </div>
          <div class="people">
            <div class="basic-info">
              <div class="name">Pets</div>
            </div>
            <div class="counter-container">
              <button mat-button (click)="decrease('pet')">-</button>
              <span class="counter-value">{{ petValue }}</span>
              <button mat-button (click)="increase('pet')">+</button>
            </div>
          </div>
        </div>
      </div>
      
      <button type="submit" (click)="onSubmit()" class="match-btn">
        Match me
      </button>
    </div>
  </div>
  <a (click)="navigateToDashboard()">
    <img class="logo" src="assets/images/white-logo-with-mobile.png" alt="">
  </a>
</header>
