<div class="loading-overlay" *ngIf="isLoading | async">
  <div class="spinner"></div>
</div>
<div id="splash-screen" class="splash-screen" *ngIf="isLoading | async">
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    fill="#9D0759"
    stroke="#9D0759"
    width="150"
    height="150"
    viewBox="0 0 500.000000 558.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,558.000000) scale(0.100000,-0.100000)"
      fill="#9D0759"
      stroke="none"
    >
      <path
        d="M2344 5570 c-189 -20 -383 -78 -554 -166 -369 -188 -653 -522 -778
   -914 -56 -176 -67 -254 -66 -480 0 -173 3 -221 22 -307 46 -210 139 -422 260
   -593 76 -107 237 -273 330 -340 34 -24 62 -47 62 -50 0 -3 -33 -18 -73 -34
   -190 -75 -436 -222 -616 -366 -125 -101 -327 -309 -422 -435 -307 -409 -471
   -863 -505 -1397 l-6 -108 216 0 216 0 0 74 c0 176 53 449 126 642 275 739 931
   1252 1718 1345 143 17 452 6 592 -20 657 -123 1202 -537 1495 -1134 129 -265
   209 -583 209 -839 l0 -68 216 0 216 0 -7 113 c-21 364 -100 667 -255 982 -89
   181 -179 324 -297 470 -90 112 -281 302 -388 386 -169 134 -393 267 -572 342
   -57 23 -103 45 -103 48 0 3 30 27 68 55 264 193 482 524 566 859 39 156 49
   261 43 440 -6 174 -25 282 -77 438 -228 684 -922 1132 -1636 1057z m311 -485
   c101 -13 134 -26 165 -65 25 -31 27 -37 12 -33 -27 7 -36 -23 -13 -45 10 -9
   32 -25 50 -34 18 -10 30 -22 27 -27 -3 -4 4 -17 15 -27 l20 -18 -23 -17 c-19
   -15 -21 -22 -13 -45 22 -60 108 -72 121 -16 14 59 50 69 96 25 24 -23 30 -25
   39 -13 8 12 15 9 39 -19 27 -32 31 -34 54 -22 37 20 86 -15 86 -62 0 -24 -77
   -27 -105 -5 -13 10 -29 18 -35 18 -7 0 -23 12 -37 26 -28 31 -57 27 -146 -18
   -47 -23 -58 -33 -54 -48 3 -12 -6 -34 -24 -56 -16 -20 -29 -39 -29 -41 0 -2 7
   -33 16 -69 16 -61 16 -67 -1 -104 -17 -38 -17 -41 -1 -59 10 -11 25 -33 34
   -50 9 -17 39 -55 65 -86 43 -50 52 -55 88 -55 22 0 62 9 89 21 43 19 51 19 76
   6 16 -8 35 -12 43 -9 11 4 12 -2 6 -29 -14 -56 -10 -94 13 -126 26 -37 27 -69
   5 -148 -9 -35 -17 -103 -18 -160 -2 -80 -6 -109 -24 -142 -34 -68 -24 -153 19
   -153 32 0 73 45 129 139 30 50 56 89 58 87 9 -9 -80 -169 -137 -244 -438 -582
   -1318 -566 -1744 32 -211 295 -255 689 -116 1036 17 42 55 114 85 160 l55 85
   -7 -50 c-3 -27 -14 -72 -24 -98 -23 -58 -23 -67 -3 -67 8 0 22 16 30 35 8 19
   17 35 21 35 3 0 3 -40 0 -89 -6 -87 -6 -89 23 -130 21 -29 37 -41 55 -41 24 0
   65 -31 65 -49 0 -5 11 -12 25 -15 14 -4 25 -13 25 -20 0 -22 48 -48 82 -44 53
   5 57 -19 15 -104 -25 -51 -37 -88 -37 -117 0 -38 6 -49 45 -88 33 -33 45 -52
   45 -74 0 -44 23 -82 69 -112 59 -39 70 -62 80 -168 11 -125 56 -333 85 -389
   14 -28 32 -48 49 -54 35 -13 43 -2 29 37 -7 19 -12 57 -12 85 0 43 5 57 30 85
   17 19 30 44 30 55 0 12 23 42 54 72 40 38 57 62 65 95 9 37 18 48 50 64 41 22
   51 37 51 81 0 42 27 109 67 168 43 62 49 111 16 143 -20 20 -125 60 -191 72
   -17 4 -43 1 -57 -5 -34 -15 -39 -7 -25 39 18 58 -5 108 -56 122 -20 6 -56 25
   -78 43 -33 27 -50 33 -91 34 -28 1 -60 4 -71 8 -14 4 -41 -4 -79 -24 -63 -33
   -109 -39 -119 -14 -3 9 -6 33 -6 55 0 33 -4 41 -26 51 -19 9 -24 17 -19 32 15
   46 17 76 6 76 -7 0 -28 -16 -47 -35 -39 -40 -54 -38 -54 5 0 55 29 108 78 140
   74 49 112 37 112 -33 0 -34 2 -38 15 -27 8 7 15 28 15 47 0 23 9 46 25 65 14
   16 25 35 25 42 0 17 79 91 115 107 18 9 31 23 33 37 3 17 10 22 33 22 59 0 87
   32 53 61 -14 12 -13 14 12 21 19 5 33 3 44 -7 9 -8 24 -15 33 -15 29 0 20 45
   -18 85 -19 21 -36 41 -37 44 -2 3 -17 14 -35 24 l-32 17 -44 -42 c-24 -24 -46
   -51 -50 -60 -9 -26 -27 -22 -27 6 0 13 -7 26 -15 30 -29 11 -15 26 39 41 30 9
   60 22 66 30 15 18 44 19 70 3 27 -17 56 1 53 35 -1 20 4 27 25 32 29 7 44 -6
   68 -63 17 -39 26 -36 94 31 48 47 41 46 155 32z m251 -116 c13 -22 -12 -30
   -31 -9 -18 19 -18 20 3 20 11 0 24 -5 28 -11z"
      >
        <animate
          attributeName="opacity"
          values="0.1;0.9;0.1"
          dur="1s"
          begin="0.67s"
          repeatCount="indefinite"
        />
      </path>

      <path
        d="M1206 1014 c-34 -11 -64 -31 -101 -67 -78 -79 -85 -108 -85 -362 0
   -193 2 -213 18 -228 21 -19 49 -22 72 -7 12 8 16 48 20 227 5 205 6 220 28
   254 70 113 228 109 295 -6 8 -13 14 -99 17 -238 5 -202 7 -220 24 -233 29 -21
   63 -17 80 8 13 19 16 57 16 221 0 219 5 244 59 290 78 66 185 53 248 -30 l28
   -36 3 -216 c3 -181 6 -219 19 -233 18 -20 45 -23 74 -8 18 10 19 23 19 238 0
   217 -1 229 -24 277 -30 66 -99 128 -166 150 -100 33 -217 7 -284 -64 l-29 -30
   -45 39 c-74 66 -181 86 -286 54z"
      >
        <animate
          attributeName="opacity"
          values="0.1;0.9;0.1"
          dur="1s"
          begin="0.67s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M2435 1021 c-174 -46 -286 -219 -254 -396 20 -113 83 -196 191 -252
   46 -24 65 -27 144 -28 86 0 96 2 163 37 70 35 71 36 71 14 0 -47 56 -71 96
   -42 18 13 19 28 18 208 0 175 -2 198 -23 250 -28 71 -98 147 -170 183 -58 29
   -176 42 -236 26z m136 -112 c62 -13 132 -72 160 -134 50 -109 -9 -256 -120
   -302 -41 -18 -134 -19 -174 -4 -85 33 -147 125 -147 216 0 102 71 195 170 221
   57 16 50 16 111 3z"
      >
        <animate
          attributeName="opacity"
          values="0.1;0.9;0.1"
          dur="1s"
          begin="0.67s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M3166 1015 c-57 -20 -108 -66 -138 -125 l-28 -52 0 -239 c0 -225 1
   -239 19 -249 29 -15 56 -12 74 8 13 15 16 53 19 242 3 207 5 227 23 252 29 38
   68 58 127 65 81 9 107 42 72 91 -13 18 -24 22 -72 22 -31 -1 -74 -7 -96 -15z"
      >
        <animate
          attributeName="opacity"
          values="0.1;0.9;0.1"
          dur="1s"
          begin="0.67s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M3387 1012 c-10 -10 -17 -26 -17 -34 0 -22 248 -600 265 -617 22 -23
   97 -26 122 -5 23 20 273 590 273 623 0 41 -63 66 -93 37 -8 -8 -62 -127 -122
   -265 -59 -138 -111 -251 -115 -251 -3 0 -55 114 -114 253 -59 138 -115 258
   -124 265 -23 17 -56 15 -75 -6z"
      >
        <animate
          attributeName="opacity"
          values="0.1;0.9;0.1"
          dur="1s"
          begin="0.67s"
          repeatCount="indefinite"
        />
      </path>
    </g>
  </svg>
</div>
