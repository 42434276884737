import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // Server-side error
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }

        // this.router.navigate(['/error500']);
        console.log(errorMessage);
        // Optionally, display the error to the user or log it
        Swal.fire({
          title: 'Error Occurred',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Okay',
          confirmButtonColor: "var(--primary-color)"
        })
        // console.error(errorMessage);

        // Return a user-friendly message or rethrow the error
        return throwError(() => new Error('Something went wrong, please try again later.'));
      })
    );
  }
}
