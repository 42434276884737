import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FormControl } from "@angular/forms";
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { AuthService } from "../../shared/services/auth.service";
import { SidebarService } from "../sidebar/services/sidebar.service";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CommonModule } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { formatDate } from "@angular/common";
import Swal from "sweetalert2";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    MatIcon,
    CommonModule,
    RouterModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [provideNativeDateAdapter()],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  peopleSummary: string | null = null;
  adultValue: number = 0;
  childrenValue: number = 0;
  infantValue: number = 0;
  petValue: number = 0;
  dropdownOpen: boolean = false;
  headerBackgroundColor: string = "var(--primary-color)";
  showFilter: boolean = true;
  predictions: google.maps.places.AutocompletePrediction[] = [];
  date = new FormControl<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });
  formattedDateRange: string = "";
  @ViewChild("search", { static: false }) searchElementRef!: ElementRef;
  europeanCountryCodes = [
    "AL",
    "AD",
    "AM",
    "AT",
    "AZ",
    "BY",
    "BE",
    "BA",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FI",
    "FR",
    "GE",
    "DE",
    "GR",
    "HU",
    "IS",
    "IE",
    "IT",
    "KZ",
    "XK",
    "LV",
    "LI",
    "LT",
    "LU",
    "MT",
    "MD",
    "MC",
    "ME",
    "NL",
    "MK",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "RS",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TR",
    "UA",
    "GB",
    "VA",
  ];

  selectedCity?: string;
  startDate: Date | null = null;
  endDate: Date | null = null;

  private readonly MAX_VALUE = 10;

  constructor(
    private router: Router,
    private authService: AuthService,
    public sidebarService: SidebarService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnInit() {
    let userType = localStorage.getItem("userType");
    if (userType === "marveler") {
      this.headerBackgroundColor = "var(--primary-color)";
      this.showFilter = true;
    } else if (userType === "counselor") {
      this.headerBackgroundColor = "var(--secondary-color)";
      this.showFilter = false;
    } else {
      this.headerBackgroundColor =
        "linear-gradient(90deg, rgba(18, 103, 106, 1) 0%, rgb(155, 227, 254) 50%, rgba(157, 7, 89, 1) 100%)";
    }
  }

  async ngAfterViewInit() {
    await google.maps.importLibrary("places");
    const searchBox = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );

    searchBox.addListener("place_changed", () => {
      const place = searchBox.getPlace();
      const addressComponents = place.address_components || [];

      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );
      const countryCode = countryComponent ? countryComponent.short_name : "";
      if (this.europeanCountryCodes.includes(countryCode)) {
        console.log("Selected city is in Europe:", place.name);
        this.searchElementRef.nativeElement.value = place.name;
        this.selectedCity = place.name;
      } else {
        this.searchElementRef.nativeElement.value = "";
        console.log("Selected city is not in Europe:", place.name);
        this.selectedCity = place.name;
      }
    });
  }

  onSubmit() {
    let ErrorMessages = [];
    if(this.startDate === null){
      ErrorMessages.push("Please select the start date");
    }
    if(this.endDate === null){
      ErrorMessages.push("Please select the end date");
    }
    if(this.selectedCity === undefined){
      ErrorMessages.push("Please select the city");
    }
    if(this.adultValue === 0 && this.childrenValue === 0 && this.infantValue === 0 && this.petValue === 0){
      ErrorMessages.push("Please select the number of people");
    }
    if(ErrorMessages.length > 0){
      Swal.fire({
        title: "Error!",
        html: ErrorMessages.join("<br/>"),
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    localStorage.setItem("searchParams", JSON.stringify({
      startDate: this.startDate,
      endDate: this.endDate,
      selectedCity: this.selectedCity,
      adultValue: this.adultValue,
      childrenValue: this.childrenValue,
      infantValue: this.infantValue,
      petValue: this.petValue
    }));
    this.router.navigate(["/dashboard/explorer"]);
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getPeopleSummary(): string {
    const summary = [];
    if (this.adultValue > 0)
      summary.push(`${this.adultValue} Adult${this.adultValue > 1 ? "s" : ""}`);
    if (this.childrenValue > 0)
      summary.push(
        `${this.childrenValue} Child${this.childrenValue > 1 ? "ren" : ""}`
      );
    if (this.infantValue > 0)
      summary.push(
        `${this.infantValue} Infant${this.infantValue > 1 ? "s" : ""}`
      );
    if (this.petValue > 0)
      summary.push(`${this.petValue} Pet${this.petValue > 1 ? "s" : ""}`);
    return summary.length > 0 ? summary.join(", ") : "";
  }

  increase(type: string) {
    switch (type) {
      case "adult":
        if (this.adultValue < this.MAX_VALUE) this.adultValue++;
        break;
      case "children":
        if (this.childrenValue < this.MAX_VALUE) this.childrenValue++;
        break;
      case "infant":
        if (this.infantValue < this.MAX_VALUE) this.infantValue++;
        break;
      case "pet":
        if (this.petValue < this.MAX_VALUE) this.petValue++;
        break;
    }
    this.peopleSummary = this.getPeopleSummary();
  }

  decrease(type: string) {
    switch (type) {
      case "adult":
        this.adultValue = Math.max(0, this.adultValue - 1);
        break;
      case "children":
        this.childrenValue = Math.max(0, this.childrenValue - 1);
        break;
      case "infant":
        this.infantValue = Math.max(0, this.infantValue - 1);
        break;
      case "pet":
        this.petValue = Math.max(0, this.petValue - 1);
        break;
    }
    this.peopleSummary = this.getPeopleSummary();
  }

  onDateChange(event: MatDatepickerInputEvent<Date[]>): void {
    const [start, end] = event.value || [];
    if (start && end) {
      this.formattedDateRange = `${this.formatDate(start)} - ${this.formatDate(
        end
      )}`;
    } else {
      this.formattedDateRange = "";
    }
  }

  private formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  navigateToDashboard(): void {
    let userType = localStorage.getItem("userType");
    if (userType === "marveler") {
      this.router.navigate(["/dashboard/dashboard-marveler"]);
    } else if (userType === "counselor") {
      this.router.navigate(["/dashboard/dashboard-counselor"]);
    } else {
      this.router.navigate(["/home"]);
    }
  }

  formatConvert(date: Date | null): string {
    return date ? formatDate(date, "d MMM yyyy", "en-GB") : "";
  }

  onChangedStartDate(event: any) {
    if (event.value) {
      this.startDate = event.value;
    } else {
      this.startDate = null;
    }
  }

  onChangedEndDate(event: any) {
    if (event.value) {
      this.endDate = event.value;
    } else {
      this.endDate = null;
    }
  }
}
